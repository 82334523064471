import { useEffect, useState } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import "./Styles/AllPrograms.css";
import searchIcon from "../Images/Programs/search.png";
import { useNavigate } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { toast } from "react-toastify";

export default function Trainer() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const [allTrainer, setAllTrainer] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [isDeleteTrainer, setIsDeleteTrainer] = useState(false);
  const [deleteTrainerId, setDeleteTrainerId] = useState(0);

  var urlParams;

  if (searchInput.length > 0) {
    urlParams = new URLSearchParams({
      name: searchInput,
      pageNumber: page,
      pageSize: 20,
    }).toString();
  } else {
    urlParams = new URLSearchParams({
      pageNumber: page,
      pageSize: 20,
    }).toString();
  }
  var url = `/trainer/get-trainer-list?` + urlParams;

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
    api
      .get(url, {
        headers: "",
      })
      .then((response) => {
        console.log("trainer Data:: ", response.data.data);

        setAllTrainer(response.data.data);
        setPagination(response.data.totalRecords);
      });
  }, [url]);

  const fetchAllRecipes = () => {
    api
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAllTrainer(response.data.data);
        setPagination(response.data.totalRecords);
      });
  };

  function handleDeleteRecipeInitial(programId) {
    setDeleteTrainerId(programId);
    setIsDeleteTrainer(true);
  }

  async function handleDeleteProgramFinal() {
    await api
      .delete(`/trainer/delete-trainer?trainerId=${deleteTrainerId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        fetchAllRecipes();
        setIsDeleteTrainer(false);
        toast.success("Trainer Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          theme: "dark",
        });
      });
  }

  const totalPages = Math.ceil(pagination === 0 ? 1 / 20 : pagination / 20);

  const handleNext = () => {
    setPage(page + 1);
  };

  const handleBack = () => {
    setPage(page - 1);
  };

  return (
    <div className="allProgramsPage">
      {isDeleteTrainer && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Are you sure you want to delete
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              this Trainer?
            </li>
            <div style={{ marginLeft: 100 }}>
              <button
                onClick={() => handleDeleteProgramFinal()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffba26",
                  color: "#0a2e28",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsDeleteTrainer(false)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e63946",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      <div style={{ display: "flex", width: "100%" }}>
        <LeftNavigationBar trainer={1} />

        {/* <div style={{ width: windowSize.current[0], marginLeft: 300, display: 'flex', justifyContent: 'center' }}> */}
        <div className="mainBody">
          <div
            style={{
              marginTop: 30,
              marginLeft: 30,
              marginRight: 30,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              src={searchIcon}
              alt="searchIcon"
              style={{
                width: 25,
                height: 25,
                position: "absolute",
                marginLeft: 20,
                marginTop: 12,
              }}
            />
            <input
              type="search"
              placeholder="Search by Trainer Name"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              style={{
                // marginLeft: 30,
                width: "50%",
                height: 50,
                paddingLeft: 60,
                paddingRight: 20,
                background: "#F5F5F5",
                borderStyle: "none",
                fontFamily: "LexendRegular400",
                fontSize: 14,
                // textTransform: 'capitalize'
              }}
            />

            <button
              onClick={() => navigate("/add-new-trainer")}
              style={{
                cursor: "pointer",
                borderStyle: "none",
                width: 240,
                height: 50,
                backgroundColor: "#FFF2F2",
                fontFamily: "LexendSemiBold600",
                fontSize: 18,
                color: "#4176FF",
              }}
            >
              Add New Trainer
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{ fontFamily: "LexendRegular400" }}>ID</th>
                <th style={{ fontFamily: "LexendRegular400" }}>Trainer Name</th>
                <th style={{ fontFamily: "LexendRegular400" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allTrainer.map((val, key) => {
                return (
                  <tr key={key}>
                    <td
                      style={{
                        textAlign: "center",
                        paddingLeft: 15,
                        paddingRight: 15,
                      }}
                    >
                      {key + 1}
                    </td>
                    <td style={{ textAlign: "left", paddingLeft: 5 }}>
                      {`${val.firstName} ${val.lastName}`}
                    </td>
                    <td style={{ textAlign: "center", paddingLeft: 5 }}>
                      <button
                        onClick={() => navigate(`/preview-trainer/${val.id}`)}
                        style={{
                          backgroundColor: "white",
                          width: 100,
                          paddingTop: 5,
                          paddingBottom: 5,
                          borderStyle: "none",
                          fontFamily: "LexendMedium500",
                        }}
                      >
                        Preview
                      </button>{" "}
                      <br />
                      <button
                        onClick={() => handleDeleteRecipeInitial(val.id)}
                        style={{
                          marginTop: 10,
                          color: "#FF0000",
                          backgroundColor: "#FFF2F2",
                          width: 100,
                          paddingTop: 5,
                          paddingBottom: 5,
                          borderStyle: "none",
                          fontFamily: "LexendMedium500",
                        }}
                      >
                        Delete
                      </button>{" "}
                      <br />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {allTrainer.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <button
                onClick={handleBack}
                disabled={page === 1 ? true : false}
                style={{
                  cursor: "pointer",
                  width: 35,
                  height: 30,
                  borderWidth: 0,
                  backgroundColor: "#0a2e28",
                }}
              >
                <FiChevronLeft
                  size={24}
                  color="white"
                  style={{ marginTop: 2, marginLeft: -2 }}
                />
              </button>

              <input
                style={{
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 20,
                  width: 30,
                  textAlign: "center",
                  height: 25,
                  borderWidth: 1,
                  borderColor: "#999999",
                  borderStyle: "solid",
                }}
                value={page}
              />
              <div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>
                /
              </div>
              <div style={{ fontFamily: "LexendMedium500", fontSize: 14 }}>
                {totalPages}
              </div>
              <button
                onClick={handleNext}
                disabled={page === totalPages ? true : false}
                style={{
                  cursor: "pointer",
                  marginLeft: 20,
                  width: 35,
                  height: 30,
                  borderWidth: 0,
                  backgroundColor: "#0a2e28",
                }}
              >
                <FiChevronRight
                  size={24}
                  color="white"
                  style={{ marginTop: 2 }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
