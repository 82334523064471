import "./Styles/AddNewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import api from "../Contexts/BaseUrl";
import { useEffect, useState, useRef, createRef } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import Compressor from "compressorjs";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Styles/Recipe.css";
import { toast } from "react-toastify";
// import AWS from "aws-sdk";

import {
  uploadFiles,
  uploadImage,
  isVimeoVideo,
} from "../Components/helpers/fileUpload";

export default function AddNewTrainer() {
  const VIDEO_URL = "https://d2zw1jyj1gqhys.cloudfront.net/";

  //Upload image to AWS Start

  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // Upload Image to AWS Ends

  const hiddenInputImageUpload = useRef(null);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");
  const [imageThumbnailLink, setImageThumbnailLink] = useState("");
  const [key, setKey] = useState("");

  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
    setImageSelected(false);
  };
  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      console.log("img::", newFile);

      setFile(newFile);
      setThumbnail(newFile);
      setFileupdated(newFile);
      const newKey = Math.random().toString(36).substring(7);
      setKey(newKey);
      setTempUploadedImageUrl(URL.createObjectURL(newFile));
      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
    setShowReactCropper(true);
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setFile(null);
    setThumbnail(null);
  };

  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorNumber, setIsErrorNumber] = useState(false);
  const [serving, setServing] = useState("");
  const [showReactCropper, setShowReactCropper] = useState(false);
  const [fileupdated, setFileupdated] = useState();
  const [isImageSelected, setImageSelected] = useState(false);
  const [description, setDescription] = useState("");
  const hiddenInputVideoUpload = useRef(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const urlId = useParams();

  useEffect(() => {
    if (urlId.trainerId) {
      var user = JSON.parse(localStorage.getItem("auth"));
      api
        .get(`/trainer/get-trainer-by-id?trainerId=${urlId.trainerId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
        .then((response) => {
          console.log("response", response.data);
          setTempUploadedImageUrl(response.data.imageUrl);
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setDescription(response.data.description);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickUploadVideo = (e) => {
    hiddenInputVideoUpload.current.click();
  };

  // const handleChangeUploadedVideo = (e) => {
  //   if (e.target.files.length > 0) {
  //     setVideoFile(e.target.files[0]);
  //     const videoUrl = URL.createObjectURL(e.target.files[0]);
  //     setTempUploadedVideoUrl(videoUrl);
  //   }
  //   if (hiddenInputVideoUpload.current) {
  //     hiddenInputVideoUpload.current.value = "";
  //   }
  // };

  const cropperRef = createRef();

  // const handleRemoveVideo = () => {
  //   if (dayVideoAvailable) {
  //     setDeleteExistingVideo(true);
  //   } else {
  //     setVideoFile(null);
  //     setTempUploadedVideoUrl(null);
  //     setUploadedVideoUrl(null);
  //   }
  // };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
  }, []);

  useEffect(() => {
    if (showReactCropper) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showReactCropper]);

  const cancleCropperModal = () => {
    setShowReactCropper(false);
    setTempUploadedImageUrl("");
  };

  const compressImage = async (blob, size) => {
    return new Promise((resolve) => {
      new Compressor(blob, {
        // Adjust compression settings as needed
        quality: 0.9,
        maxWidth: size,
        maxHeight: size,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
      });
    });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
        // Further compress the cropped image
        const compressedBlob = await compressImage(blob, 1000);
        const thumbnailBlob = await compressImage(blob, 300);
        const fixedAspectRatioImage = new File(
          [compressedBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        const fixedThumbnailImage = new File(
          [thumbnailBlob],
          fileupdated.name,
          {
            type: fileupdated.type,
          }
        );
        setTempUploadedImageUrl(URL.createObjectURL(fixedAspectRatioImage));
        setFile(fixedAspectRatioImage);
        setThumbnail(fixedThumbnailImage);
      }, fileupdated.type);
    }
    setShowReactCropper(false);
  };

  const hitEditRecipeApi = async (
    firstName,
    lastName,
    imageUrl,
    description,
    id
  ) => {
    await api
      .post(
        "/trainer/update-trainer",
        {
          FirstName: firstName,
          LastName: lastName,
          ImageUrl: imageUrl,
          Description: description,
          Id: id,
          // "createdDate": "0001-01-01T00:00:00"
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsLoading(false);
        toast.success("Trainer Edit Successfully", {
          position: "top-right",
          autoClose: 2000,
          theme: "dark",
        });
        navigate("/trainer");
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  const handleCreateRecipe = async (e) => {
    e.preventDefault();
    // validateTimeInput();
    setIsLoading(true);
    if (!tempUploadedImageUrl) {
      setImageSelected(true);
      setIsLoading(false);

      return;
    } else {
      let thumbnailResponse;
      let imageResponse;
      if (file) {
        imageResponse = await uploadImage(file);
        thumbnailResponse = await uploadImage(thumbnail);
      }

      if (urlId.trainerId) {
        let imageUrl = file ? imageResponse.Location : tempUploadedImageUrl;
        hitEditRecipeApi(
          firstName,
          lastName,
          imageUrl,
          description,
          urlId.trainerId
        );
      } else {
        await api
          .post(
            "/trainer/create-trainer",
            {
              FirstName: firstName,
              LastName: lastName,
              ImageUrl: imageResponse.Location,
              Description: description,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(async (response) => {
            setIsLoading(false);
            console.log("trainer Created:: ", response);

            toast.success("Trainer Created Successfully", {
              position: "top-right",
              autoClose: 2000,
              theme: "dark",
            });
            navigate("/trainer");
          })
          .catch((err) => {
            setIsError(true);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <div className="addNewProgramPage">
      {isSuccess && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Your trainer has been created
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              successfully and saved as Draft.
            </li>
            <button
              onClick={() => navigate(-1)}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "pointer",
                backgroundColor: "#ffba26",
                color: "#0a2e28",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 20,
                paddingLeft: 20,
                border: 0,
                fontFamily: "LexendMedium500",
                fontSize: 14,
                borderRadius: 4,
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      <div style={{ display: "flex" }}>
        <LeftNavigationBar trainer={1} />
        <div className="mainBody">
          <div
            onClick={() =>
              urlId.trainerId
                ? navigate(`/preview-trainer/${urlId.trainerId}`)
                : navigate("/trainer")
            }
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            {urlId.trainerId ? "Edit Trainer" : "Add New Trainer"}
          </li>
          <input
            type="file"
            accept="image/*"
            ref={hiddenInputImageUpload}
            onChange={handleChangeUploadedImage}
            style={{ display: "none" }}
          />
          <form
            onSubmit={handleCreateRecipe}
            style={{ marginTop: 30, marginBottom: 30 }}
          >
            <div style={{ display: "flex" }}>
              {showReactCropper && (
                <div id="modal10" className="cropper-modal-wrapper">
                  <Cropper
                    ref={cropperRef}
                    zoomTo={0}
                    initialAspectRatio={16 / 11}
                    aspectRatio={16 / 11}
                    preview=".img-preview"
                    src={tempUploadedImageUrl}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    className="croppper-body"
                  />
                  <button
                    onClick={cancleCropperModal}
                    className="cropper-cancel-btn"
                  >
                    Cancel
                  </button>
                  <button className="cropper-submit" onClick={getCropData}>
                    Save
                  </button>
                </div>
              )}
              {tempUploadedImageUrl?.length === 0 ? (
                <div
                  onClick={handleClickUploadImage}
                  style={{
                    width: 200,
                    height: 200,
                    background: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  <img
                    key={key}
                    src={uploadImageIcon}
                    alt="uploadIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 14,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Upload Image
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 40,
                    }}
                  >
                    Upload the thumb of the trainer
                  </li>
                  {isImageSelected || tempUploadedImageUrl ? (
                    <li
                      style={{
                        marginTop: 20,
                        fontFamily: "LexendMedium500",
                        color: "#FF0000",
                        fontSize: 16,
                      }}
                    >
                      Please add image also!
                    </li>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                <div>
                  <div onClick={handleClickUploadImage} style={{}}>
                    <img
                      src={tempUploadedImageUrl}
                      alt="uploadedImage"
                      style={{
                        width: 200,
                        height: 200,
                        resize: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <button
                    onClick={handleCancelImage}
                    style={{
                      backgroundColor: "white",
                      paddingTop: 5,
                      paddingBottom: 5,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 10,
                    }}
                  >
                    Remove Image
                  </button>
                </div>
              )}
              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  style={{
                    marginTop: 20,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <textarea
                  required
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows="4"
                  cols="50"
                  style={{
                    borderStyle: "none",
                    paddingLeft: 20,
                    paddingVertical: 20,
                    fontFamily: "LexendRegular400",
                    marginTop: 25,
                    width: 340,
                  }}
                ></textarea>

                <br />
                {isErrorNumber && (
                  <div
                    style={{
                      paddingTop: 5,
                      color: "red",
                    }}
                  >
                    Please enter number only
                  </div>
                )}
              </div>
              <div style={{ marginLeft: 70 }}>
                {!isLoading ? (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                    type="submit"
                  >
                    {urlId.trainerId ? "Save" : "Create"}
                  </button>
                ) : (
                  <button
                    style={{
                      width: 140,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                    type="button"
                  >
                    <Spinner
                      style={{
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </button>
                )}
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    "Failed creating the trainer!"
                  </li>
                )}
              </div>
            </div>
            <div>
              {/* <input
                type="file"
                accept="video/mp4"
                ref={hiddenInputVideoUpload}
                onChange={handleChangeUploadedVideo}
                style={{ display: "none" }}
              /> */}
              {/* {tempUploadedVideoUrl === null ? (
                <div
                  onClick={handleClickUploadVideo}
                  style={{
                    cursor: "pointer",
                    width: 200,
                    marginTop: 10,
                    background: "#ffffff",
                    padding: "1px 5px",
                  }}
                >
                  <img
                    src={uploadImageIcon}
                    alt="uploadImageIcon"
                    style={{
                      maxHeight: 150,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 40,
                    }}
                  />
                  <li
                    style={{
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      textAlign: "center",
                      marginTop: 25,
                    }}
                  >
                    Upload Video
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "#8f8d9f",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    Upload the whole video of the trainer
                  </li>
                  <li
                    style={{
                      fontFamily: "LexendMedium500",
                      fontSize: 12,
                      color: "black",
                      textAlign: "center",
                      marginTop: 5,
                      marginBottom: 20,
                    }}
                  >
                    *Only mp4 format is supported
                  </li>
                </div>
              ) : (
                <div
                  style={{
                    borderStyle: "none",
                    width: "100%",
                    paddingTop: urlId.trainerId ? 20 : 0,
                  }}
                >
                  {uploadedVideoUrl ? (
                    <>
                      {/* <VimeoPlayer
                        video={tempUploadedVideoUrl}
                        autoplay
                        className="video-wrapper"
                      /> 
                      <video width="250" height="200" controls>
                        <source
                          src={`${
                            VIDEO_URL + isVimeoVideo(tempUploadedVideoUrl)
                          }`}
                        />
                      </video>

                      <button
                        onClick={handleRemoveVideo}
                        style={{
                          display: "block",
                          marginLeft: "60px",
                          marginTop: 10,
                          fontFamily: "LexendRegular400",
                          fontSize: 14,
                        }}
                      >
                        Remove Video
                      </button>
                    </>
                  ) : (
                    ""
                  )

                  {videoFile &&
                    (!isVideoLoading ? (
                      <>
                        <video
                          style={{
                            width: 250,
                            height: 250,
                            borderStyle: "none",
                            display: "block",
                          }}
                          controls
                        >
                          <source src={tempUploadedVideoUrl} type="video/mp4" />
                        </video>
                        <button
                          onClick={handleRemoveVideo}
                          style={{
                            display: "block",
                            marginLeft: "60px",
                            marginTop: 10,
                            fontFamily: "LexendRegular400",
                            fontSize: 14,
                          }}
                        >
                          Remove Video
                        </button>
                      </>
                    ) : (
                      <>
                        <video
                          style={{
                            width: 250,
                            height: 250,
                            borderStyle: "none",
                            display: "block",
                          }}
                          controls
                        >
                          <source src={tempUploadedVideoUrl} type="video/mp4" />
                        </video>
                        <button
                          onClick={handleRemoveVideo}
                          style={{
                            display: "block",
                            marginLeft: "60px",
                            marginTop: 10,
                            fontFamily: "LexendRegular400",
                            fontSize: 14,
                          }}
                        >
                          Remove Video
                        </button>
                      </>
                    ))}
                </div>
              ) */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
