import { useRef, useState, useEffect } from "react";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import "./Styles/Categories.css";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import api from "../Contexts/BaseUrl";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../Images/Equipments/delete.png";
import editIcon from "../Images/Equipments/edit_icon.png";

import backIcon from "../Images/AddAdmin/back.png";
import { uploadImage } from "../Components/helpers/fileUpload";
export default function Categories() {
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }
    fetchAllEquipments();
  }, []);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [categoryName, setCategoryName] = useState("");
  const [allCategories, setAllCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");
  const [file, setFile] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const fetchAllEquipments = () => {
    api
      .get("/workoutProgramCategories/get-all-categories", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAllCategories(response.data);
        console.log("response Data", response.data);

        setFile(null);
        setTempUploadedImageUrl("");
      });
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    let imageResponse;
    setIsLoading(true);
    if (file) {
      imageResponse = await uploadImage(file);
    }

    if (isEdit) {
      if (file || tempUploadedImageUrl) {
        await api
          .post(
            "WorkoutProgramCategories/edit-category",
            {
              workoutProgramCategoryName: categoryName,
              ImageUrl: file ? imageResponse.Location : tempUploadedImageUrl,
              WorkoutProgramCategoryId: deleteEquipmentId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setIsEdit(false);
            setIsSuccessful(true);
            setIsLoading(false);
            setCategoryName("");
            fetchAllEquipments();
          })
          .catch((err) => {
            setIsError(true);
            setIsLoading(false);
          });
      } else {
        setIsError(true);
        setIsLoading(false);
      }

      console.log("add is Edit api.......");
    } else {
      if (file) {
        await api
          .post(
            `/workoutProgramCategories/create-category`,
            {
              workoutProgramCategoryName: categoryName,
              ImageUrl: imageResponse.Location,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setIsSuccessful(true);
            setIsLoading(false);
            setCategoryName("");
            fetchAllEquipments();
          })
          .catch((err) => {
            setIsError(true);
            setIsLoading(false);
          });
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  const [isDeleteEquipment, setIsDeleteEquipment] = useState(false);
  const [deleteEquipmentId, setDeleteEquipmentId] = useState(0);

  const handleDeleteEquipmentInitial = (element) => {
    setIsDeleteEquipment(true);
    setDeleteEquipmentId(element);
  };

  const handleEditCategory = (categoryId) => {
    setIsEdit(true);
    setDeleteEquipmentId(categoryId);
    const editCategory = allCategories.find(
      (category) => category.workoutProgramCategoryId === categoryId
    );
    console.log(editCategory);
    setCategoryName(editCategory.workoutProgramCategoryName);
    setTempUploadedImageUrl(editCategory.imageUrl ?? "");
    setAllCategories((prevCategories) =>
      prevCategories.filter(
        (category) => category.workoutProgramCategoryId !== categoryId
      )
    );
  };

  const handleDeleteEquipmentFinal = async () => {
    await api
      .post(
        `/workoutProgramCategories/delete-category?categoryId=${deleteEquipmentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsDeleteEquipment(false);
        fetchAllEquipments();
      })
      .catch((err) => {
        console.log(err.response.status);
        console.log("Failed deleting equipment");
      });
  };
  const hiddenInputImageUpload = useRef(null);
  const handleChangeUploadedImage = (e) => {
    if (e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setTempUploadedImageUrl(URL.createObjectURL(newFile));

      if (hiddenInputImageUpload.current) {
        hiddenInputImageUpload.current.value = "";
      }
    }
  };
  const handleClickUploadImage = (e) => {
    hiddenInputImageUpload.current.click();
    setIsError(false);
  };

  const handleCancelImage = () => {
    setTempUploadedImageUrl("");
    setFile(null);
  };
  return (
    <div
      className="categoriesPage"
      style={{ height: windowSize.current[1], paddingBottom: 100 }}
    >
      <LeftNavigationBar allPrograms={1} />

      {isDeleteEquipment && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal">
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginTop: 35,
              }}
            >
              Are you sure you want to delete
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                marginBottom: 20,
              }}
            >
              this category?
            </li>
            <div style={{ marginLeft: 100 }}>
              <button
                onClick={() => handleDeleteEquipmentFinal()}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffba26",
                  color: "#0a2e28",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                Yes
              </button>
              <button
                onClick={() => setIsDeleteEquipment(false)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#e63946",
                  color: "white",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginRight: 30,
                  border: 0,
                  fontFamily: "LexendMedium500",
                  fontSize: 14,
                  borderRadius: 4,
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
      )}
      <div className="mainBody">
        <div
          onClick={() => navigate(-1)}
          style={{
            cursor: "pointer",
            marginBottom: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
            width: 40,
            height: 40,
            borderRadius: 25,
            borderStyle: "solid",
            borderColor: "#F38CAC",
            borderWidth: 1,
          }}
        >
          <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
        </div>
        <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
          All Categories
        </li>
        <div style={{ marginTop: 25, display: "flex", flexWrap: "wrap" }}>
          {allCategories.map((val, key) => {
            return (
              <div
                id="equipmentBox"
                key={key}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={val.imageUrl}
                  alt="uploaded"
                  height={30}
                  width={30}
                  style={{ resize: "cover", marginLeft: 15 }}
                />

                <li
                  style={{
                    marginLeft: 15,
                    fontFamily: "LexendBold700",
                    fontSize: 14,
                    flexGrow: 1,
                  }}
                >
                  {val.workoutProgramCategoryName}
                </li>

                <div
                  style={{
                    marginRight: 20,
                    display: "flex",
                    gap: "10px", // Adds space between the icons
                    alignItems: "center", // Aligns icons vertically
                    padding: "5px", // Optional: Padding for aesthetics
                  }}
                >
                  <img
                    onClick={() =>
                      handleDeleteEquipmentInitial(val.workoutProgramCategoryId)
                    }
                    width={20}
                    height={20}
                    src={deleteIcon}
                    alt="deleteIcon"
                  />
                  <img
                    width={20}
                    height={20}
                    onClick={() =>
                      handleEditCategory(val.workoutProgramCategoryId)
                    }
                    src={editIcon}
                    alt="editIcon"
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div style={{ marginTop: 40 }}>
          <li style={{ fontFamily: "LexendBold700", fontSize: 28 }}>
            Add Category
          </li>
          <form
            onSubmit={handleAddCategory}
            style={{ display: "flex", marginTop: 25, gap: 20 }}
          >
            <input
              type="file"
              accept="image/*"
              ref={hiddenInputImageUpload}
              onChange={handleChangeUploadedImage}
              style={{ display: "none" }}
            />
            {tempUploadedImageUrl.length === 0 ? (
              <div id="uploadImageBox" onClick={handleClickUploadImage}>
                <img
                  src={uploadImageIcon}
                  alt="uploadImageIcon"
                  style={{
                    maxHeight: 150,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 40,
                  }}
                />
                <li
                  style={{
                    fontFamily: "LexendBold700",
                    fontSize: 14,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  Upload Icon
                </li>
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    "Please upload an Icon!"
                  </li>
                )}
              </div>
            ) : (
              <div>
                <div
                  onClick={handleClickUploadImage}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={tempUploadedImageUrl}
                    alt="uploaded"
                    style={{ maxHeight: 150, resize: "cover" }}
                  />
                </div>
                <button
                  onClick={handleCancelImage}
                  style={{
                    backgroundColor: "white",
                    paddingTop: 5,
                    paddingBottom: 5,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 10,
                  }}
                >
                  Remove Image
                </button>
              </div>
            )}
            <div style={{ width: 300, marginLeft: 0, flexDirection: "row" }}>
              <input
                type="text"
                placeholder="Type Category name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                required
                style={{
                  width: 280,
                  height: 60,
                  backgroundColor: "#ffffff",
                  borderStyle: "none",
                  paddingLeft: 20,
                  fontFamily: "LexendRegular400",
                  fontSize: 14,
                }}
              />
              {!isLoading ? (
                <button
                  style={{
                    marginTop: 28,
                    width: "100%",
                    height: 60,
                    backgroundColor: "#F38CAC",
                    borderStyle: "none",
                    color: "white",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  style={{
                    marginTop: 28,
                    width: "100%",
                    height: 60,
                    backgroundColor: "#F38CAC",
                    borderStyle: "none",
                    color: "white",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                  }}
                >
                  <Spinner
                    size={15}
                    color="white"
                    style={{ diplay: "block", margin: "auto" }}
                  />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
