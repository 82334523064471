import "./Styles/PreviewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import uploadImageIcon from "../Images/Equipments/uploadImage.png";
import timerIcon from "../Images/Programs/timer.png";
import api from "../Contexts/BaseUrl";
import { useEffect, useState } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { AiOutlinePlus } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import { ImCross } from "react-icons/im";
import { BiImage } from "react-icons/bi";
import { GrImage } from "react-icons/gr";
import { isVimeoVideo } from "../Components/helpers/fileUpload";
export default function PreviewProgram() {
  let params = useParams();
  var programId = params.programId;
  const VIDEO_URL = "https://d2zw1jyj1gqhys.cloudfront.net/";

  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [programTitle, setProgramTitle] = useState("");
  const [programSubtitle, setProgramSubtitle] = useState("");
  const [programTimeLength, setProgramTimeLength] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [programStatus, setProgramStatus] = useState(0);
  const [programImageUrl, setProgramImageUrl] = useState("");
  const [weeks, setWeeks] = useState([]);

  const [isWeekDays, setIsWeekDays] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const [weekNumber, setWeekNumber] = useState(0);
  const [previewRound, setPreviewRound] = useState([]);

  const handleViewDescritpion = () => {
    setExpanded(!expanded);
  };

  function handleWeekDays(weekId) {
    setIsWeekDays(true);
    api
      .get(`/weeks/get-individual-week?weekId=${weekId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("week Data::", response.data);

        setWeekDays(response.data.days);
        setWeekNumber(response.data.weekNumber);
      });
  }

  const [isPreview, setIsPreview] = useState(false);
  const [previewWeekNumber, setPreviewWeekNumber] = useState(0);
  const [previewDayId, setPreviewDayId] = useState(0);
  const [previewDayNumber, setPreviewDayNumber] = useState(0);
  const [previewDayTitle, setPreviewDayTitle] = useState("");
  const [previewDaySubtitle, setPreviewDaySubtitle] = useState("");
  const [previewDayTimeLength, setPreviewDayTimeLength] = useState("");
  const [previewDayKcal, setPreviewDayKcal] = useState("");

  const [previewDayDetails, setPreviewDayDetails] = useState("");
  const [previewDayExercises, setPreviewDayExercises] = useState([]);
  const [previewDayImageUrl, setPreviewDayImageUrl] = useState(null);
  const [previewDayVideoUrl, setPreviewDayVideoUrl] = useState(null);
  const [previewAllEquipments, setPreviewAllEquipments] = useState([]);

  var roundExercisesMap = new Map();
  const [roundNumbers, setRoundNumbers] = useState([]);
  const [roundExercises, setRoundExercises] = useState([]);
  const [roundName, setRoundName] = useState();

  var weekJungleDays = new Map();
  const [weekJungle, setWeekJungle] = useState([]);

  function handlePreviewDetails(weekNumber, dayId) {
    setIsPreview(true);
    setPreviewDayId(dayId);
    setPreviewWeekNumber(weekNumber);
    fetchIndividualDay(dayId);
  }

  function fetchIndividualDay(dayId) {
    api
      .get(`/days/get-individual-day?dayId=${dayId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("day Data::", response.data);
        setPreviewRound(response.data.rounds);
        setPreviewDayNumber(response.data.dayNumber);
        setPreviewDayTitle(response.data.dayTitle);

        setPreviewDaySubtitle(response.data.daySubtitle);
        setPreviewDayTimeLength(response.data.dayTimeLength);
        console.log("kCal", response.data.kCal);

        setPreviewDayKcal(response.data.kCal);
        setPreviewDayDetails(response.data.dayDetails);
        setPreviewDayExercises(response.data.exercises);
        setPreviewDayImageUrl(response.data.dayImageUrl);
        setPreviewDayVideoUrl(response.data.dayVideoUrl);
        setPreviewAllEquipments(response.data.equipments);

        for (var i = 0; i < response.data.rounds.length; i++) {
          fetchRoundExercises(
            response.data.rounds[i].roundId,
            i,
            response.data.rounds.length
          );
        }
      });
  }

  useEffect(() => {
    console.log("Fetching individual program data...");
    var user = JSON.parse(localStorage.getItem("auth"));
    if (user) {
      setToken(user.token);
    }

    fetchIndividualProgram();
  }, []);

  const fetchRoundExercises = (roundId, i, roundsLength) => {
    api
      .get(`/rounds/get-individual-round?roundId=${roundId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        roundExercisesMap.set(
          response.data.roundNumber,
          response.data.roundExercises
        );
        if (i === roundsLength - 1) {
          var arr = [];
          var arrRoundNumber = [];
          for (const value of roundExercisesMap.values()) {
            arr.push(value);
          }

          for (const key of roundExercisesMap.keys()) {
            arrRoundNumber.push(key);
          }

          setRoundExercises(arr);
          setRoundNumbers(arrRoundNumber);
        }
        setRoundName(response.data.roundName);
      });
  };

  const fetchIndividualProgram = () => {
    api
      .get(`/workoutPrograms/get-individual-program?id=${programId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setProgramTitle(response.data.workoutProgramTitle);
        setProgramSubtitle(response.data.workoutProgramSubtitle);
        setProgramTimeLength(response.data.workoutProgramTimeLength);
        setProgramDescription(response.data.workoutProgramDescription);
        setProgramImageUrl(response.data.workoutProgramImageUrl);
        setWeeks(response.data.weeks);
      });
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    await api
      .post(
        `/workoutPrograms/update-status?programId=${programId}&status=${1}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsPublished(true);
      });
  };

  const handleDraft = async (e) => {
    e.preventDefault();
    await api
      .post(
        `/workoutPrograms/update-status?programId=${programId}&status=${0}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setIsDraft(true);
      });
  };

  const containerStyle = {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    width: 360,
    height: "auto", // Set the initial height, adjust as needed
    fontFamily: "LexendRegular400",
    borderStyle: "none",
    background: "white",
    overflow: "hidden",
    whiteSpace: expanded ? "normal" : "nowrap",
    textOverflow: "ellipsis",
    transition: "height 0.3s ease",
    padding: "11px 0 11px",
  };

  const listItemStyle = {
    paddingLeft: 20,
    fontSize: 16,
    overflow: "hidden",
    textOverflow: expanded ? "initial" : "ellipsis", // Use 'initial' to disable ellipsis
    wordWrap: expanded ? "break-word" : "normal",
  };

  const handleContainerClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="previewProgramPage">
      {isPublished && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li
              style={{
                marginTop: 40,
                textAlign: "center",
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              Your program has been published
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              successfully!
            </li>
            <button
              onClick={() => navigate(-1)}
              style={{
                width: 60,
                paddingTop: 5,
                paddingBottom: 5,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 15,
                background: "#F38CAC",
                color: "white",
                fontFamily: "LexendSemiBold600",
                fontSize: 16,
                borderStyle: "none",
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      {isWeekDays && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal3">
            <li
              style={{
                fontFamily: "LexendMedium500",
                fontSize: 24,
                marginBottom: 15,
              }}
            >
              Week {weekNumber} Days
            </li>
            <div
              style={{
                display: "flex",
                borderStyle: "none",
                justifyContent: "space-between",
              }}
            >
              {weekDays.map((val, index) => {
                return (
                  <>
                    <div
                      onClick={() =>
                        handlePreviewDetails(weekNumber, val.dayId)
                      }
                      key={index}
                      style={{
                        marginTop: 10,
                        marginBottom: 20,
                        background: "#ffffff",
                        width: 120,
                        height: 100,
                        borderStyle: "dotted",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <>
                        {val.dayImageUrl !== null ? (
                          <img
                            src={val.dayImageUrl}
                            alt="day"
                            style={{ width: 120, height: 100, resize: "cover" }}
                          />
                        ) : (
                          <div>
                            <CiImageOn
                              size={34}
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            />
                            <li
                              style={{
                                fontFamily: "LexendMedium500",
                                fontSize: 18,
                              }}
                            >
                              Day {val.dayNumber}
                            </li>
                          </div>
                        )}
                      </>
                    </div>
                  </>
                );
              })}
            </div>
            <button
              onClick={() => setIsWeekDays(false)}
              style={{
                width: 70,
                paddingTop: 5,
                paddingBottom: 5,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 15,
                background: "#F38CAC",
                color: "white",
                fontFamily: "LexendSemiBold600",
                fontSize: 16,
                borderStyle: "none",
              }}
            >
              Back
            </button>
          </div>
        </>
      )}
      {isDraft && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 4,
            }}
          />
          <div id="modal2">
            <li
              style={{
                marginTop: 40,
                textAlign: "center",
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              Your program has been saved
            </li>
            <li
              style={{
                textAlign: "center",
                fontFamily: "LexendMedium500",
                fontSize: 16,
              }}
            >
              as Draft.
            </li>
            <button
              onClick={() => navigate(-1)}
              style={{
                width: 60,
                paddingTop: 5,
                paddingBottom: 5,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 15,
                background: "#F38CAC",
                color: "white",
                fontFamily: "LexendSemiBold600",
                fontSize: 16,
                borderStyle: "none",
              }}
            >
              Ok
            </button>
          </div>
        </>
      )}
      {isPreview && (
        <>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: 0.3,
              zIndex: 5,
            }}
          />
          <div id="modal">
            <div
              onClick={() => setIsPreview(false)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 40,
                height: 40,
                borderRadius: 30,
                borderStyle: "none",
                position: "absolute",
                right: 0,
                marginTop: -16,
                backgroundColor: "black",
              }}
            >
              <ImCross size={20} style={{ color: "white" }} />
            </div>
            <li
              style={{
                marginTop: 20,
                marginLeft: 20,
                fontFamily: "LexendMedium500",
                fontSize: 24,
              }}
            >
              Week {previewWeekNumber} - Day {previewDayNumber} Details
            </li>
            <div
              style={{
                marginLeft: 20,
                marginTop: 20,
                display: "flex",
                width: 560,
                borderStyle: "none",
              }}
            >
              <div
                style={{
                  width: 150,
                  height: 150,
                  borderStyle: "dashed",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {previewDayImageUrl !== null ? (
                  <img
                    src={previewDayImageUrl}
                    alt="day"
                    style={{ width: 150, height: 150, resize: "cover" }}
                  />
                ) : (
                  <BiImage
                    size={70}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      color: "#A5D7E8",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  width: 350,
                  borderStyle: "none",
                  marginLeft: 20,
                  fontFamily: "LexendRegular400",
                  fontSize: 16,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    // height: 40,
                    backgroundColor: "#FAFAFD",
                  }}
                >
                  <li style={{ marginLeft: 20, paddingTop: 8 }}>
                    {previewDayTitle}
                  </li>
                </div>
                <div
                  style={{
                    marginTop: 15,
                    width: "100%",
                    // height: 40,

                    backgroundColor: "#FAFAFD",
                  }}
                >
                  <div style={containerStyle} onClick={handleContainerClick}>
                    <li style={listItemStyle}>{previewDaySubtitle}</li>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 15,
                    width: "100%",
                    height: 40,
                    backgroundColor: "#FAFAFD",
                  }}
                >
                  <li style={{ marginLeft: 20, paddingTop: 8 }}>
                    {previewDayTimeLength}
                  </li>
                </div>
                <div
                  style={{
                    marginTop: 15,
                    width: "100%",
                    height: 40,
                    backgroundColor: "#FAFAFD",
                  }}
                >
                  <li style={{ marginLeft: 20, paddingTop: 8 }}>
                    {previewDayKcal}
                  </li>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                borderStyle: "none",
                marginLeft: 20,
                marginRight: 20,
                fontFamily: "LexendRegular400",
                fontSize: 16,
                background: "#FAFAFD",
              }}
            >
              <li style={{ paddingTop: 10, paddingBottom: 10 }}>
                Day Details: {previewDayDetails}
              </li>
            </div>
            <div
              style={{
                marginTop: 20,
                marginLeft: 0,
                borderStyle: "none",
                background: "white",
              }}
            >
              <li style={{ fontFamily: "LexendMedium500", fontSize: 24 }}>
                Equipment's Needed
              </li>
              <div
                style={{
                  marginTop: 10,
                  marginLeft: 20,
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {previewAllEquipments.map((val, key) => {
                  return (
                    <div
                      id="equipmentBox"
                      key={key}
                      style={{
                        width: 150,
                        height: 135,
                        marginRight: 20,
                        marginBottom: 30,
                        borderRadius: 20,
                      }}
                    >
                      <div
                        style={{
                          borderStyle: "none",
                          marginLeft: 20,
                          marginRight: 10,
                          marginBottom: 10,
                          height: 80,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          src={val.equipmentImage}
                          alt="home"
                          style={{ marginTop: 30, width: 100, height: 50 }}
                        />
                      </div>
                      <li
                        style={{
                          marginLeft: 20,
                          fontFamily: "LexendBold700",
                          fontSize: 14,
                        }}
                      >
                        {val.equipmentName}
                      </li>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                paddingTop: 30,
                marginLeft: 0,
                marginRight: 0,
                paddingBottom: 50,
                borderStyle: "none",
                background: "white",
              }}
            >
              <li style={{ fontFamily: "LexendMedium500", fontSize: 24 }}>
                Day {previewDayNumber} Video
              </li>
              {previewDayVideoUrl !== null ? (
                <video
                  style={{
                    height: 200,
                    width: 250,
                    borderStyle: "none",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  controls
                >
                  <source
                    src={`${VIDEO_URL + isVimeoVideo(previewDayVideoUrl)}`}
                  />
                </video>
              ) : (
                // <VimeoPlayer
                //   video={previewDayVideoUrl}
                //   autoplay
                //   className="video-wrapper"
                // />

                <li
                  style={{
                    fontFamily: "LexendMedium500",
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  Video doesn't exist!
                </li>
              )}
            </div>
            <div
              style={{
                marginLeft: 0,
                marginRight: 0,
                paddingTop: 20,
                borderStyle: "none",
                background: "white",
              }}
            >
              <li
                style={{
                  fontFamily: "LexendMedium500",
                  fontSize: 24,
                  paddingBottom: 20,
                }}
              >
                Exercises Included
              </li>

              {previewDayExercises?.map((item, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      paddingBottom: 40,
                      fontFamily: "LexendMedium500",
                      fontSize: 16,
                    }}
                  >
                    <div
                      style={{
                        width: 40,
                        height: 120,
                        backgroundColor: "#FAFAFD",
                        borderStyle: "none",
                      }}
                    >
                      <div
                        style={{
                          borderStyle: "solid",
                          borderColor: "#4176FF",
                          borderWidth: 2,
                        }}
                      />
                      <li style={{ textAlign: "center", marginTop: 50 }}>
                        {key + 1}
                      </li>
                    </div>
                    <div
                      style={{
                        marginLeft: 30,
                        width: 120,
                        height: 120,
                        backgroundColor: "#FAFAFD",
                        borderStyle: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.exerciseImageUrl !== null ? (
                        <img
                          src={item.exerciseImageUrl}
                          alt="exercise image"
                          style={{ width: 120, height: 120, resize: "cover" }}
                        />
                      ) : (
                        <BiImage size={50} style={{ color: "#A5D7E8" }} />
                      )}
                    </div>
                    <div
                      style={{
                        marginLeft: 30,
                        width: 300,
                        height: 120,
                        borderStyle: "none",
                      }}
                    >
                      <div
                        style={{
                          height: 50,
                          borderStyle: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#FAFAFD",
                        }}
                      >
                        {item.exerciseTitle}
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                          height: 50,
                          borderStyle: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#FAFAFD",
                        }}
                      >
                        {item.exerciseTime}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ marginLeft: 0 }}>
              {/* dskfjsdfksjdflkjdfkj */}
              {previewRound.map((item, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      marginBottom: 40,
                      fontFamily: "LexendSemiBold600",
                      fontSize: 18,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#ffffff",
                        borderStyle: "none",
                        paddingTop: 0,
                        paddingBottom: 20,
                      }}
                    >
                      <li>Round {item.roundName}</li>
                    </div>
                    {item.roundExercises.map((item, keyVal) => {
                      return (
                        <div
                          key={keyVal}
                          style={{
                            display: "flex",
                            paddingBottom: 40,
                            fontFamily: "LexendMedium500",
                            fontSize: 16,
                            background: "white",
                          }}
                        >
                          <div
                            style={{
                              width: 40,
                              height: 120,
                              backgroundColor: "#FAFAFD",
                              borderStyle: "none",
                            }}
                          >
                            <div
                              style={{
                                borderStyle: "solid",
                                borderColor: "#4176FF",
                                borderWidth: 2,
                              }}
                            />
                            <li style={{ textAlign: "center", marginTop: 50 }}>
                              {keyVal + 1}
                            </li>
                          </div>
                          <div
                            style={{
                              marginLeft: 30,
                              width: 120,
                              height: 120,
                              backgroundColor: "#FAFAFD",
                              borderStyle: "none",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {item.roundExerciseImageUrl !== null ? (
                              <img
                                src={item.roundExerciseImageUrl}
                                alt="roundExercise"
                                style={{
                                  width: 120,
                                  height: 120,
                                  resize: "cover",
                                }}
                              />
                            ) : (
                              <BiImage size={50} style={{ color: "#A5D7E8" }} />
                            )}
                          </div>

                          <div
                            style={{
                              marginLeft: 30,
                              width: 300,
                              height: 120,
                              borderStyle: "none",
                            }}
                          >
                            <div
                              style={{
                                height: 50,
                                borderStyle: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#FAFAFD",
                              }}
                            >
                              {item.roundExerciseTitle}
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                                height: 50,
                                borderStyle: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#FAFAFD",
                              }}
                            >
                              {item.roundExerciseTimeLength}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}

              {/* {roundExercises.map((subArray, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      marginBottom: 40,
                      fontFamily: "LexendSemiBold600",
                      fontSize: 18,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#ffffff",
                        borderStyle: "none",
                        paddingTop: 0,
                        paddingBottom: 20,
                      }}
                    >
                      <li>Round {roundName}</li>
                    </div>
                    {subArray.map((item, keyVal) => {
                      return (
                        <div
                          key={keyVal}
                          style={{
                            display: "flex",
                            paddingBottom: 40,
                            fontFamily: "LexendMedium500",
                            fontSize: 16,
                            background: "white",
                          }}
                        >
                          <div
                            style={{
                              width: 40,
                              height: 120,
                              backgroundColor: "#FAFAFD",
                              borderStyle: "none",
                            }}
                          >
                            <div
                              style={{
                                borderStyle: "solid",
                                borderColor: "#4176FF",
                                borderWidth: 2,
                              }}
                            />
                            <li style={{ textAlign: "center", marginTop: 50 }}>
                              {keyVal + 1}
                            </li>
                          </div>
                          <div
                            style={{
                              marginLeft: 30,
                              width: 120,
                              height: 120,
                              backgroundColor: "#FAFAFD",
                              borderStyle: "none",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {item.roundExerciseImageUrl !== null ? (
                              <img
                                src={item.roundExerciseImageUrl}
                                alt="roundExercise"
                                style={{
                                  width: 120,
                                  height: 120,
                                  resize: "cover",
                                }}
                              />
                            ) : (
                              <BiImage size={50} style={{ color: "#A5D7E8" }} />
                            )}
                          </div>

                          <div
                            style={{
                              marginLeft: 30,
                              width: 300,
                              height: 120,
                              borderStyle: "none",
                            }}
                          >
                            <div
                              style={{
                                height: 50,
                                borderStyle: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#FAFAFD",
                              }}
                            >
                              {item.roundExerciseTitle}
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                                height: 50,
                                borderStyle: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#FAFAFD",
                              }}
                            >
                              {item.roundExerciseTimeLength}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })} */}
            </div>
            <div style={{ height: 50 }} />
          </div>
        </>
      )}

      <div style={{ display: "flex", width: "100%" }}>
        <LeftNavigationBar allPrograms={1} />
        <div className="mainBody">
          <div
            onClick={() => navigate("/all-programs")}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li
            onClick={() => console.log(weekJungle)}
            style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}
          >
            Preview Program
          </li>
          <div style={{ marginTop: 30, marginBottom: 60 }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: 200,

                  background: "#ffffff",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {programImageUrl !== null && programImageUrl.length > 0 ? (
                  <img
                    src={programImageUrl}
                    alt="profile"
                    style={{ width: 200, height: 200, resize: "contain" }}
                  />
                ) : (
                  <BiImage
                    size={70}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      color: "#A5D7E8",
                    }}
                  />
                )}
              </div>
              <div style={{ marginLeft: 30 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 360,
                    height: 40,
                    fontFamily: "LexendRegular400",
                    borderStyle: "none",
                    background: "white",
                  }}
                >
                  <li style={{ paddingLeft: 20, fontSize: 16 }}>
                    {programTitle}
                  </li>
                </div>
                {/* <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    width: 360,
                    height: "auto",
                    fontFamily: "LexendRegular400",
                    borderStyle: "none",
                    background: "white",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    transition: "height 0.3s ease", // Add a smooth transition effect
                  }}
                >
                  <li style={{ paddingLeft: 20, fontSize: 16, overflow: "hidden", textOverflow: "ellipsis" }}>
                    {programSubtitle}
                  </li>
                </div> */}
                <div style={containerStyle} onClick={handleContainerClick}>
                  <li style={listItemStyle}>{programSubtitle}</li>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    width: 360,
                    height: 40,
                    fontFamily: "LexendRegular400",
                    borderStyle: "none",
                    background: "white",
                  }}
                >
                  {/* <img
                    src={timerIcon}
                    alt="timerIcon"
                    style={{ maxHeight: 18, marginLeft: 20 }}
                  /> */}
                  <li style={{ paddingLeft: 20, fontSize: 16 }}>
                    {programTimeLength}
                  </li>
                </div>
                {/* <li style={{ fontSize: 12, marginTop: 5, fontFamily: 'LexendBold700' }}>*Format: MM:SS</li> */}
              </div>
              <div style={{ marginLeft: 70 }}>
                {!isLoading ? (
                  programStatus === 0 ? (
                    <button
                      onClick={handlePublish}
                      style={{
                        width: 170,
                        height: 50,
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        backgroundColor: "#F38CAC",
                        fontFamily: "LexendBold700",
                        fontSize: 18,
                        color: "#ffffff",
                        borderStyle: "none",
                      }}
                    >
                      Publish
                    </button>
                  ) : (
                    <button
                      onClick={handleDraft}
                      style={{
                        width: 170,
                        height: 50,
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        backgroundColor: "#F38CAC",
                        fontFamily: "LexendBold700",
                        fontSize: 18,
                        color: "#ffffff",
                        borderStyle: "none",
                      }}
                    >
                      Save As Draft
                    </button>
                  )
                ) : (
                  <button
                    style={{
                      width: 170,
                      height: 50,
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: "#F38CAC",
                      fontFamily: "LexendBold700",
                      fontSize: 18,
                      color: "#ffffff",
                      borderStyle: "none",
                    }}
                  >
                    <Spinner
                      style={{
                        color: "white",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </button>
                )}
                <button
                  onClick={() => navigate(`/edit-program/${programId}`)}
                  style={{
                    marginTop: 25,
                    width: 170,
                    height: 50,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "#EAF0FF",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                    color: "#4176FF",
                    borderStyle: "none",
                  }}
                >
                  Edit
                </button>
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    Failed creating the program!
                  </li>
                )}
              </div>
            </div>
            <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 24,
                marginTop: 60,
                marginBottom: 25,
              }}
            >
              Program Description
            </li>
            <div style={{ padding: 20, backgroundColor: "white" }}>
              <li style={{ fontFamily: "LexendRegular400", fontSize: 16 }}>
                {programDescription.split("\n").map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </li>
            </div>
          </div>

          <li
            style={{
              fontFamily: "LexendSemiBold600",
              fontSize: 24,
              marginTop: 60,
              marginBottom: 25,
            }}
          >
            Weeks
          </li>
          <div
            style={{
              marginTop: 20,
              fontFamily: "LexendSemiBold600",
              fontSize: 18,
              borderStyle: "none",
            }}
          >
            {weeks.map((val, key) => {
              return (
                <div
                  id="weekDayBox"
                  key={key}
                  style={{
                    height: 50,
                    borderStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#ffffff",
                    marginBottom: 20,
                  }}
                >
                  <li style={{ marginTop: 15, marginLeft: 10 }}>
                    Week {val.weekNumber}
                  </li>
                  <button
                    onClick={() => handleWeekDays(val.weekId)}
                    style={{
                      fontFamily: "LexendSemiBold600",
                      fontSize: 14,
                      width: 100,
                      background: "#4176FF",
                      borderStyle: "none",
                      color: "white",
                    }}
                  >
                    View Days
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
