import "./Styles/AddNewProgram.css";
import LeftNavigationBar from "../Components/LeftNavigationBar";
import backIcon from "../Images/AddAdmin/back.png";
import { useNavigate, useParams } from "react-router-dom";
import api from "../Contexts/BaseUrl";
import { useEffect, useState } from "react";
import "react-activity/dist/library.css";
import "cropperjs/dist/cropper.css";
import { isVimeoVideo } from "../Components/helpers/fileUpload";
export default function PreviewTrainer() {
  const [tempUploadedImageUrl, setTempUploadedImageUrl] = useState("");

  const navigate = useNavigate();
  const [programTitle, setProgramTitle] = useState("");
  const [description, setDescription] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isError, setIsError] = useState(false);

  const urlId = useParams();
  const VIDEO_URL = "https://d2zw1jyj1gqhys.cloudfront.net/";

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("auth"));
    api
      .get(`/trainer/get-trainer-by-id?trainerId=${urlId.trainerId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      })
      .then((response) => {
        setTempUploadedImageUrl(response.data.imageUrl);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setDescription(response.data.description);
        console.log("response.data.videoUrl", response.data.videoUrl);
        console.log("pop", response.data.videoUrl.split("/").pop());
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="addNewProgramPage">
      <div style={{ display: "flex" }}>
        <LeftNavigationBar trainer={1} />
        <div className="mainBody">
          <div
            onClick={() => navigate("/trainer")}
            style={{
              cursor: "pointer",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              width: 40,
              height: 40,
              borderRadius: 25,
              borderStyle: "solid",
              borderColor: "#F38CAC",
              borderWidth: 1,
            }}
          >
            <img src={backIcon} alt="backicon" style={{ maxHeight: 12 }} />
          </div>
          <li style={{ fontFamily: "LexendSemiBold600", fontSize: 24 }}>
            Preview Trainer
          </li>
          <form style={{ marginTop: 30, marginBottom: 30 }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: 200,
                  cursor: "pointer",
                }}
              >
                <img
                  src={tempUploadedImageUrl}
                  alt="TrainerImage"
                  style={{
                    height: 180,
                    width: 180,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <input
                  required
                  disabled
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{
                    marginTop: 20,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <input
                  required
                  disabled
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  style={{
                    marginTop: 20,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
                <br />
                <textarea
                  required
                  disabled
                  rows="4"
                  cols="50"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{
                    marginTop: 25,
                    width: 340,
                    height: 40,
                    borderStyle: "none",
                    paddingLeft: 20,
                    fontFamily: "LexendRegular400",
                  }}
                />
              </div>
              <div style={{ marginLeft: 70 }}>
                <button
                  onClick={() => navigate(`/edit-trainer/${urlId.trainerId}`)}
                  style={{
                    marginTop: 25,
                    width: 170,
                    height: 50,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "#EAF0FF",
                    fontFamily: "LexendBold700",
                    fontSize: 18,
                    color: "#4176FF",
                    borderStyle: "none",
                  }}
                >
                  Edit
                </button>
                {isError && (
                  <li
                    style={{
                      marginTop: 20,
                      fontFamily: "LexendMedium500",
                      color: "#FF0000",
                      fontSize: 16,
                    }}
                  >
                    Failed creating the program!
                  </li>
                )}
              </div>
            </div>
            {/* <li
              style={{
                fontFamily: "LexendSemiBold600",
                fontSize: 20,
                marginTop: 30,
                marginBottom: 25,
              }}
            >
              Trainer Video
            </li> */}
            {/* {tempUploadedVideoUrl ? (
              <video width="250" height="200" controls>
                <source
                  src={`${VIDEO_URL + isVimeoVideo(tempUploadedVideoUrl)}`}
                />
              </video>
            ) : (
              <p> No Video Found </p>
            )} */}
          </form>
        </div>
      </div>
    </div>
  );
}
